<template>
    <sm-editable-list
      :controllerName="controllerName"
      :breadcrumbs="breadcrumbs"
      :tableCaption="tableCaption"
      :tableHeaders="tableHeaders"
      :filterFields="filterFields"
    />
  </template>
  
  <script>
  // mixins
  import editableList from '@/mixins/editableList.js';
  // components
  import SmEditableList from '@/components/views/SmEditableList.vue';
  
  export default {
    name: 'AtsAccess',
  
    components: {
      SmEditableList,
    },
  
    mixins: [editableList],
  
    data() {
      return {
        controllerName: 'AtsAccess',
        tableCaption: 'Аккаунты АТС домофонов',
        breadcrumbs: [
          {
            text: 'Мониторинг',
            route: { name: 'MonitoringMain' },
          },
          {
            text: 'Аккаунты АТС домофонов',
          },
        ],
        tableHeaders: [
          { text: 'номер АТС', alias: 'atsNumber', order: 'atsNumber' },
          { text: 'Пароль АТС', alias: 'atsPassword', order: 'atsPassword' },
          { text: 'База', alias: 'baseName', order: 'baseName' },
          { text: 'Адрес', alias: 'houseAddress', order: 'houseAddress' },
          { text: 'Помещение', alias: 'premiseNumber', order: 'premiseNumber' },
        ],
        filterFields: [
          { text: 'База', alias: 'baseName' },
          { text: 'Адрес', alias: 'houseAddress' },
          { text: 'номер АТС', alias: 'atsNumber' },
          { text: 'Пароль АТС', alias: 'atsPassword' },
          { text: 'Помещение', alias: 'premiseNumber' },
        ],
      };
    },
  };
  </script>
  